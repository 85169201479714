
.mbox-checkbox-field-container{
    width: 100%;
    display: flex;
    padding: .125rem 0 ;
    border-bottom: 1.5px solid transparent;
    
    &:hover{
        /*border-color: var(--app-navy-1);*/
        transition: all .3s;
        box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
        input[type=checkbox] {transform: scale(1.6);}
    }
    &__input{
        flex: 1;
        display: flex;
        align-items: center;
        margin: 0;
    }
    input[type=checkbox] {
        cursor: pointer;
    }
}
