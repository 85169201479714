
:root {
  --mbox-dark-blue: #041B37;
  --mbox-basic-red: #BA1A1A;
  --mbox-navy-blue:#D0F8FF;/*#89BBF4;*/
  --mbox-basic-green:#04CC0C;
  --mbox-basic-gray: #808C9B;
  --mbox-basic-lightgray: #d3d3d3;
  --mbox-basic-warning:#f8760b; /*#ffc107;  FF8E33*/ 
  
  --mbox-page-bg:#0E233A;
  --mbox-primary-90:#FFDCC6;
  --mbox-main-text-color:#fcfcfc;
  --mbox-card-bg:#19314C;
  --mbox-link-color:#FF8E33;
  --mbox-icon-background:#D0F8FF;
  --mbox-card-middle-icon-color:#008391;
  --mbox-breadcrubs-color:#22BCCF;
  // --mbox-page-bg:#CCD5AE;
  // --mbox-primary-90:#FFDCC6;
  // --mbox-main-text-color:#000000;
  // --mbox-card-bg:#E9EDC9;
  // --mbox-link-color:#8F6000;
  // --mbox-icon-background: #B2EDEC;
  // --mbox-card-middle-icon-color:#008391;
  // --mbox-breadcrubs-color:#008391;

  //Typography for headline large 
  --mbox-headline-large-font-size: 24px;
  --mbox-headline-large-font-weight:400;
  --mbox-headline-large-line-height: 32.68px;

  //Typography for headline medium
  --mbox-headline-medium-font-size: 18px;
  --mbox-headline-medium-font-weight:400;
  --mbox-headline-medium-line-height:24px;

  //Typography for title large
  --mbox-title-large-font-size: 16px;
  --mbox-title-large-font-weight:600;
  --mbox-title-large-line-height:24px;

  //Typography for title medium
  --mbox-title-medium-font-size: 16px;
  --mbox-title-medium-font-weight:400;
  --mbox-title-medium-line-height:24px;

  //Typography for captions
  --mbox-captions-font-size: 14px;
  --mbox-captions-font-weight:400;
  --mbox-captions-line-height:21px;

  --mbox-login-color:#000;

  //Overrides
  --mbox-text-danger:#FFB4AB;
  --mbox-text-danger2:#EC665E;
  --toastify-color-warning:rgba(255, 142, 51, 1);/*#f1c40f*/;


}
html,
body,
#root{
  width: 100%;
  height: 100%;
  color: var(--mbox-main-text-color);
  background-color: var(--mbox-page-bg);
}
.ipl-modal__content{
  color:var(---mbox-login-color);
}
.mbox-page{
  width: 100%;
  // min-height: 100%;
  overflow: hidden;
  background-color: var(--mbox-page-bg);
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  .error-summary{
    clear: both;
    color:var(--mbox-basic-red)
  }
}
.extraClass{
  word-break: break-all;
}

.mbox-headline-large{
  font-size:var(--mbox-headline-large-font-size);
  font-weight: var(--mbox-headline-large-font-weight);
  line-height:var(--mbox-headline-large-line-height);
}

.mbox-headline-medium{
  font-size:var(--mbox-headline-medium-font-size);
  font-weight: var(--mbox-headline-medium-font-weight);
  line-height:var(--mbox-headline-medium-line-height);
}

.mbox-title-large{
  font-size:var(--mbox-title-large-font-size);
  font-weight: var(--mbox-title-large-font-weight);
  line-height:var(--mbox-title-large-line-height);
}

.mbox-title-medium{
  font-size:var(--mbox-title-medium-font-size);
  font-weight: var(--mbox-title-medium-font-weight);
  line-height:var(--mbox-title-medium-line-height);
}

.mbox-captions{
  font-size:var(--mbox-captions-font-size);
  font-weight: var(--mbox-captions-font-weight);
  line-height:var(--mbox-captions-line-height);
}
.jme-modal-container{
  color: #111;
}

.array-item{
  margin: .5rem;
  box-shadow: rgba(204, 202, 202, 0.16) 0px 1px 4px;  
  padding: 15px;

  &-remove{
    background-color: var(--mbox-basic-red);
    border-radius: 8px;

    &::after{
      content: 'Remove'
    }
  }

  i.glyphicon { display: none; }
}

.btn-add{
  margin:.5rem;
  font-size: medium;
  font-weight: 700;
  background-color: transparent;
  border: 2px solid var(--mbox-basic-warning);
  color: var(--mbox-basic-warning);
  border-radius: 8px;
  
  &:hover{
    color:white;
    background-color: var(--mbox-basic-warning);
    border: 2px solid transparent;
  }
  &:focus{
    background-color: transparent;
    border: 2px solid var(--mbox-basic-warning);
    outline: none !important;
    
  }
  &::after{
    content:'Add'
  }
}





#header{
  background-color: transparent;
  padding: 10px 25px;
}

#root{
  background-color: transparent;
}

.contentsession-icons .card-img-top{
  text-align: center;
  font-size: 35px;
}

.contentsession-icons .main-icons{
  font-size: 35px;
}

.main-icons .card{
  min-width: 250px;
  max-width: 250px;
}

.list-group a{
  color: rgb(73, 80, 87);
}


.text-center{
  text-align: center;
}

.mbox-message{
  &__error{
    font-size: 1.75rem;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mbox-basic-red);
    i{
      font-size: 1.75rem;
      color: var(--mbox-basic-red);
      margin-right: .5rem;
    }
  }
}


.button-mbox-primary,
.button-mbox-navy,
.button-mbox-danger,
.button-mbox-success,
.button-mbox-secondary,
.button-mbox-warning{
  &:hover{
      transform: translateY(-1px) !important;
  }
}


.button-mbox-primary {
  
  &,
  &:hover{
      background-color: var(--mbox-dark-blue);
      color: var(--mbox-navy-blue);
  }

  &.btn-outline {
      color: var(--mbox-dark-blue);
      border-color: var(--mbox-dark-blue);
      background-color: transparent;
  }

  &.btn-outline:hover {
      color: var(--mbox-navy-blue);
      background-color: var(--mbox-dark-blue);
      border-color: var(--mbox-dark-blue);
  }

  &.btn-outline:active svg,
  &.btn-outline:hover svg {
      fill: #fff !important;
  }

  &.btn-outline:not(:disabled):not(.disabled).active,
  &.btn-outline:not(:disabled):not(.disabled):active{
      background-color: var(--basic-dark);
      border-color: var(--basic-dark);
  }
}

.button-mbox-navy {
  &,
  &:hover{
      background-color: var(--mbox-navy-blue);
      border-color: var(--mbox-navy-blue);
      color: var(--mbox-dark-blue);        
  }

  &.btn-outline {
      color: var(--mbox-navy-blue);
      border-color: var(--mbox-navy-blue);
      background-color: transparent;
  }

  &.btn-outline:hover {
      color: var(--mbox-dark-blue);
      background-color: var(--mbox-navy-blue);
      border-color: var(--mbox-navy-blue);
  }

  &.btn-outline:active svg,
  &.btn-outline:hover svg {
      fill: #fff !important;
  }

  &.btn-outline:not(:disabled):not(.disabled).active,
  &.btn-outline:not(:disabled):not(.disabled):active{
      background-color: var(--basic-dark);
      border-color: var(--basic-dark);
  }
}

.button-mbox-danger {
  &,
  &:hover{
      background-color: var(--mbox-basic-red);
      color: #fff;
  }

  &.btn-outline {
      color: var(--mbox-basic-red);
      border-color: var(--mbox-basic-red);
      background-color: transparent;
  }

  &.btn-outline:hover {
      color: #fff;
      background-color: var(--mbox-basic-red);
      border-color: var(--mbox-basic-red);
  }

  &.btn-outline:active svg,
  &.btn-outline:hover svg {
      fill: #fff !important;
  }

  &.btn-outline:not(:disabled):not(.disabled).active,
  &.btn-outline:not(:disabled):not(.disabled):active{
      background-color: var(--basic-dark);
      border-color: var(--basic-dark);
  }
}

.button-mbox-success {
  &,
  &:hover{
      background-color: var(--mbox-basic-green);
      border-color: var(--mbox-basic-green);
      color: #fff;
  }

  &.btn-outline {
      color: var(--mbox-basic-green);
      border-color: var(--mbox-basic-green);
      background-color: transparent;
  }

  &.btn-outline:hover {
      color: #fff;
      background-color: var(--mbox-basic-green);
      border-color: var(--mbox-basic-green);
  }

  &.btn-outline:active svg,
  &.btn-outline:hover svg {
      fill: #fff !important;
  }

  &.btn-outline:not(:disabled):not(.disabled).active,
  &.btn-outline:not(:disabled):not(.disabled):active{
      background-color: var(--basic-dark);
      border-color: var(--basic-dark);
  }
}

.button-mbox-secondary {
  &,
  &:hover{
      background-color: var(--mbox-basic-gray);
      border-color: var(--mbox-basic-gray);
      color: #fff;        
  }

  &.btn-outline {
      color: var(--mbox-basic-gray);
      border-color: var(--mbox-basic-gray);
      background-color: transparent;
  }

  &.btn-outline:hover {
      color: #fff;
      background-color: var(--mbox-basic-gray);
      border-color: var(--mbox-basic-gray);
  }

  &.btn-outline:active svg,
  &.btn-outline:hover svg {
      fill: #fff !important;
  }

  &.btn-outline:not(:disabled):not(.disabled).active,
  &.btn-outline:not(:disabled):not(.disabled):active{
      background-color: var(--basic-dark);
      border-color: var(--basic-dark);
  }
}

.button-mbox-warning {
  &,
  &:hover{
      background-color: var(--mbox-basic-warning);
      border-color: var(--mbox-basic-warning);
      color: #fff;        
  }
  
  &.btn-outline {
      color: var(--mbox-basic-warning);
      border-color: var(--mbox-basic-warning);
      background-color: transparent;
  }

  &.btn-outline:hover {
      color: #fff;
      background-color: var(--mbox-basic-warning);
      border-color: var(--mbox-basic-warning);
  }

  &.btn-outline:active svg,
  &.btn-outline:hover svg {
      fill: #fff !important;
  }

  &.btn-outline:not(:disabled):not(.disabled).active,
  &.btn-outline:not(:disabled):not(.disabled):active{
      background-color: var(--basic-dark);
      border-color: var(--basic-dark);
  }
}

.array-item {
  margin: .5rem;
  box-shadow: rgba(204, 202, 202, 0.16) 0px 1px 4px;
  padding: 15px;

  &-remove {
    background-color: var(--mbox-basic-red);
    border-radius: 8px;

    &::after {
      content: 'Remove'
    }
  }

  i.glyphicon,
  span.glyphicon {
    display: none;
  }
}

.btn-add {
  margin: .5rem;
  font-size: medium;
  font-weight: 700;
  background-color: transparent;
  border: 2px solid var(--mbox-basic-warning);
  color: var(--mbox-basic-warning);
  border-radius: 8px;

  &:hover {
    color: white;
    background-color: var(--mbox-basic-warning);
    border: 2px solid transparent;
  }

  &:focus {
    background-color: transparent;
    border: 2px solid var(--mbox-basic-warning);
    outline: none !important;

  }

  &::after {
    content: 'Add'
  }
}

.jme-disabled{
  opacity: .57;
  pointer-events: none;
}

.text-danger{
  color: var(--mbox-text-danger)!important;
}

.login-container .login__box .login__box__login_btn[data-v-4be2afbc]{
  background-color: var(--mbox-link-color);
  border: 1px solid var(--mbox-link-color);
}

.login-container .login__box .login__box__login_btn[data-v-4be2afbc]:disabled{
  background-color: var(--mbox-link-color);
  pointer-events: none;
  opacity: 50%;
}

.links{
  color: var(--mbox-link-color);
}




