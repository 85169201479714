.mBoxes-container{

}
.mBoxes-title-item-container{
    color:var(--mbox-main-text-color);
    padding:0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.mBoxes-title-container{
    margin-bottom:2rem;
    color:var(--mbox-main-text-color);
    font-size: 24px;
    font-weight: 400;
}

.mBoxes-formTitle-container{
    margin-left: 0;
    margin-right: 0;
}