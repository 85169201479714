.m-box-message-fs{
    
    //background-color: rgba(0,0,0, .7);
    &__text{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        font-size: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        color: #fff;
    }
}