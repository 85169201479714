.eventsList-container{
    color:var(--mbox-main-text-color);
    margin-bottom:2rem;
}
.eventsList-title-container{
    font-weight:400;
    font-size: 24px;
}
.eventsList-title-item-container{
    /* padding:0;
    word-break: break-all; */
    /* font-size: 18px;
    font-weight: 400;
    line-height: 24px; */
}

.eventsList-formTitle-container{
    margin-left: 0;
    margin-right: 0;
}
