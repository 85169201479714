.form-desc{
    display: flex;
    justify-content: space-between;
}
.feature-container{
    display: flex;
    align-items: center;
    margin-right: .25rem;
}
.form-desc__value{
    /*background-color: rgba(255,255,255, .7);
    color: #000;
    padding: .125rem .25rem;
    border-radius: .125rem;*/
}
.feature-container .feature-icon{
    font-size: 1.2rem;
    cursor: pointer;
}
.feature-container .feature-icon:hover{
    transform: scale(1.1);
    transition: all .23s;
}
.feature-text{
    margin-right: .5rem;
    font-size: .85rem;
}

.styles-module_close__2I1sI{
    opacity:0.6;
}