.custom-range-container{
    width: 100%;
    display: flex;
}
.custom-range-container__input{
    flex: 1;
}
.custom-range-container__value{
    font-weight: bold;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    font-size: 1.1rem;
}