.me-sticky-container{
    position: relative;
    &.sticky {
        position: fixed;
        top: 0;
        padding: .35rem .5rem;
        z-index: 9;
        border-bottom: 1px solid #60527D;
        box-shadow: 5px 5px 10px rgba(0,0,0, 0.3);
      }
}