.section-container{
}
.session-header{
    /* font-size: 1.5rem; */
    color: gray;;
    font-weight: bold;
    /* text-shadow: 1px 1px 1px #eaeaea; */
    margin-bottom: 2rem;
    margin-top: -.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.session-header_date{
    font-size: 1.2rem;
}
.session-header_title{
    font-size: 1.2rem;
    margin-top: 5px;
}
.session-header_date::after {
    content: "|";
    font-size: 1.5rem;
    margin: 0 0.25rem;
}

.section-title-container{
    margin: 1rem 0 1.25rem 0;
}
.section-title{
    font-size: 1.3rem;
    font-weight: 400;
    color:var(--mbox-main-text-color);
}

@media (max-width: 768px) {
    .session-header_date{
        font-size: 1rem;
    }
    .session-header_title{
        font-size: 1rem;
    }
}