.apps-config{
    /*width: 100vw;
    height: 100vh;*/
    background-color: var(--mbox-page-bg);
    padding-bottom: 3rem;

    &--title-container{
        margin: 1.5rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        margin-bottom: .75rem;
        
    }
    &__heading{
        font-size: 1.45rem;
        span{
            /*background-color: #A2ACBD;
            color: var(--mbox-page-bg);*/
            font-weight: bold;
            border-bottom: 2px solid var(--rjsf-main-color);
        }
        
    }


    .btn-back-button{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5rem;
        padding-right:5px;
        margin-right: 1.5rem;
        font-size: 20px;
    }
    .mbox-alert{
        justify-content: unset;
    }

    .me-form-container #root > .form-group{
        padding: 0 .5rem;
        &:nth-child(even){
            background-color: rgba(58, 130, 207, .07);
        }        
    }

    .array-item{
        max-width: 342px;
    }


}
