.services-page {
    .servicesPage-container {
        margin-top: 2rem;
        margin-left: 0;
        margin-right: 0;
    }

    .servicesPage-title-container {
        font-weight: 400;
        font-size: 24px;
        color: var(--mbox-main-text-color);
    }

    .servicesPage-children-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }

    .servicesPage-children-link {
        color: var(--mbox-link-color)
    }
    .cardWithIcon-body{
        justify-content: unset;
    }
    .cardWithIcon-container__footer{
        margin-top: auto;
        .cardWithIcon-link-iconcontainer i{
            margin-top: 1px;
        }
    }
}