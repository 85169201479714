.custom-string-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow-x: auto;
}
.custom-string-container-input{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.custom-string-container-previewImage{
    max-height: 100px;
    border-radius: 8px;
    margin-left: 1rem;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.custom-string-container-not-valid-url-text{
    /*color:var(--mbox-basic-red);*/
    color: var(--mbox-text-danger2);
    font-weight: 600;
    margin-top:.5rem;
    margin-left: 1rem;
}

.custom-string-container-image-upload-preview{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:.5rem;
}