.contentSessionCard-container{
    color:var(--mbox-main-text-color);
    background-color: var(--mbox-card-bg);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-top:1rem;
    margin-left: 0;
    margin-right: 0;
}

.contentSessionCard-container:hover{
    box-shadow: rgba(246, 248, 250, 0.2) 0px 8px 24px;
}
.contentSessionPage-title-container{
    margin-bottom:2rem;
}

.contentSessionCard-link-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentSessionCard-link{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding:.5rem;
    border:1px solid var(--mbox-link-color);
    color: var(--mbox-link-color) !important;
}

.contentSessionCard-link:hover{
    background-color: var(--mbox-link-color);
    border:none;
    color: #fcfcfc !important;
    transform:scale(1.1)
}

@media only screen and (max-width: 992px) {
    .contentSessionCard-container {
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .contentSessionCard-link-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 1.5rem;
    }
  }