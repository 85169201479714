@mixin animate-scale {
    transform: scale(1.1);
    transition: all .15s;
}

.copyButton-container{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.copyButton__button{
    color:var(--mbox-basic-gray) !important;
    &:hover{
        @include  animate-scale;
    }
}
