.contentSessionPage-container{
    color:var(--mbox-main-text-color);
}

.contentSessionPage-title-item-container{
    padding:0;
}

.contentSessionPage-formTitle-container{
    margin-left: 0;
    margin-right: 0;
}
