.reasign-modal__content{
    background-color: var(--mbox-page-bg);
    
}

.reasign-modal__dropdown-button{
    width: 100%;
    position: relative;
    white-space:normal;
    text-align: center;
}

.reasign-modal__content__body{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.reasing-modal__current-room-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reasing-modal__close-button{
    background-color: transparent;
    border: none;
    color: var(--mbox-main-text-color);
    padding: 1rem;
}

.reasing-modal__close-button:hover{
    font-weight: 600;
}

.dropdown-toggle::after{
    position: absolute;
    right: .5rem;
    top: 50%;
}