:root{
    --mb-alert-primary-bg:#FFB786;
    --mb-alert-primary-color:#502400;
    --mb-alert-secondary-bg:#A1C9FF;
    --mb-alert-secondary-color:#00325A;
    --mb-alert-teritary-bg:#4FD8EB;
    --mb-alert-teritary-color:#00363D;
    --mb-alert-error-bg:#FFB4AB;
    --mb-alert-error-color:#690005;
    --mb-alert-warning-bg:rgba(255, 142, 51, .7);
    --mb-alert-warning-color:rgba(114, 54, 0, 1);
}

.mbox-alert{
    
    position: relative;
    box-sizing: border-box;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    &.type-success{
        background-color: var(--mb-alert-teritary-bg);
        color: var(--mb-alert-teritary-color);
    }
    &.type-info{
        // background-color: var(--mb-alert-secondary-bg);
        // color: var(--mb-alert-secondary-color);
        background-color: var(--mb-alert-primary-bg);
        color: var(--mb-alert-primary-color);
    }
    &.type-warning{
        background-color: var(--mb-alert-primary-bg);
        color: var(--mb-alert-primary-color);
        // color: var(--app-color-dark);
    }
    &.type-danger{
        background-color: var(--mb-alert-error-bg);
        color: var(--mb-alert-error-color);
    }
    &.size-m{
        font-size: 1.25rem;
    }
    &.size-l{
        font-size: 1.5rem;
    }
    &.size-xl{
        font-size: 2rem;
    }
    > i{
        margin-right: .75rem;
    }
    .btn-close-floating{
        position: absolute;
        margin: 0;
        top: .35rem;
        right: .35rem;
        font-size:1.35rem;
        cursor: pointer;
        &:hover{
            transform: scale(1.1);
        }
    } 
}