.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem;
}

.header-container__logo-container {
    padding: .5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-container__logo-container::after {
    content: '';
    border-bottom: 3px solid #4C5F74;
    margin-top: 1.5rem;
    width: 100%;
}

@media only screen and (max-width: 576px) {
    .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.header-list-container {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;

}

.header-list--item-container {
    display: flex;
    align-items: center;
    padding: .5rem;
    margin-top: .5rem;
}

.header-list--item-container--link:hover {
    background-color: #FF8E33;
    padding: .5rem;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 700;
}

.header-logout-button{
    color: var(--mbox-link-color);
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--mbox-link-color);
    border-radius: 12px;
    position: absolute;
    right: 2rem;
    top:2.5rem;
    padding: 5px 10px;
    transition: all .3s;
}

.header-logout-button:hover{
    background-color: var(--mbox-link-color);
    border: 1px solid transparent;
    color: var(--mbox-main-text-color);
}