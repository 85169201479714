.mBoxListCard-container{
    color: var(--mbox-main-text-color);
    background-color: var(--mbox-card-bg);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-top:1rem;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}
.mbox-list-row-container{
    width: 100%;
    margin: 5px 0;
}
.mbox-list-item-title-container{
    display: flex;
    align-items: center;
}
.mBoxListCard-container:hover{
    box-shadow: rgba(246, 248, 250, 0.2) 0px 8px 24px;
}

.mBoxListCard-link-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.mBoxListCard-link{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding:.5rem;
    border:1px solid var(--mbox-link-color);
    color:var(--mbox-link-color);
    background-color: transparent;
    cursor: pointer;
}

.mBoxListCard-link:hover{
    background-color: var(--mbox-link-color);
    border:1px solid transparent;
    color:#fcfcfc;
    /* color:var(--mbox-main-text-color) */
}

.btn-gear{
    font-size: 1.7rem;
    color: var(--mbox-basic-gray);
    cursor: pointer;
    
}

.btn-gear:hover{
        transform:  scale(1.23);
}

