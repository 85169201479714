:root {

    --rjsf-main-color: #DE7418;
    --dark-blue-70: rgba(222, 116, 24, .7);
    --app-button-color:#f8760b;
    --app-button-bg:#fff;
}

.apps-config {
    .test-form-container{
        display: flex;
        flex-direction: row;
        .me-form-container{
            flex: 1;
        }
        .me-app-preview{
            position: relative;
            padding-left: .75rem;
            border-left: 1px dashed var(--app-button-color);
            margin-left: 0.5rem;
            flex: 0 0 40%;
            user-select: none;
            overflow: hidden;
            transition: all 1s;
            ;
            &--has-fullscreen{
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0,0,0,.7);
                padding:0 .75rem;
                border: none;
                overflow-y: auto;
                .jme-mlook,
                .jme-airport{
                    transform:none !important;
                    transform-origin: unset !important;
                    width: 100% !important;
                    height: 1080px !important;
                }
            }
            &__header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: .5rem;
                border-bottom:1px dashed rgba(0, 72, 128, 1);
                .info-text{
                   display: flex; 
                   flex-direction: column;
                }
                .manage-size{
                    font-size: 1.5rem;
                    cursor: pointer;
                    margin-right: .5rem;
                    i:hover{
                        transform: scale(1.1);
                        transition: all .2s;
                        
                    }
                }
                .jme-live-icon-container{
                    .text{
                        color: #fff !important;
                        font-size: 1.2rem;
                        margin-left: .2rem;
                    }
                }
            }
            .mbox-jme-qna-app{
                background-color: #A5ABBD;
                padding: .125rem;

                .jme-rooms-overview-widget{
                    --mtv-list-bg: var(--qna-rooms-list-bg) !important;
                    --mtv-list-color: var(--qna-rooms-list-color) !important;
                }                
            }
            .jme-airport,
            .jme-mlook{
                transform: scale(0.5);
                transform-origin: 0 0;
            }
            .jme-airport{
                transform: scale(0.4);
                width: 1920px;
            }
            .jme-mlook{
                .jme-airport__room-view{
                    position: relative;
                }
            }
            .me-sticky-container{
                overflow: hidden;
            }
            
        }
    }
  
    .me-form-config {       
        .me-btn-form-submit,
        .me-btn-form-submit:not(:disabled):not(.disabled):active {
            float: right;
            background-color: var(--rjsf-main-color) !important;
            border-color: var(--rjsf-main-color) !important;
            margin: .5rem 0 1rem 0;
            font-size: 1.4rem;
            letter-spacing: 1px;
            min-width: 200px;
        }

        .me-btn-form-submit::after {
            content: "\f0c7";
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            padding-left: .75rem;

        }

        .me-btn-form-submit:hover {
            transform: scale(1.05);
            transition: all .2s;
        }
    }


    .me-form-config .field-boolean input[type=checkbox],
    #root_appSpecificSettings_availableTabs input[type=checkbox] {
        margin-right: .75rem;
    }

    #root_appSpecificSettings_availableTabs {
        display: flex;
        justify-content: space-around;
    }

    /*adding legend styles*/
    .me-form-config {
        &__footer{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 2.5rem;
            @media only screen and (min-width: 999px) { 
                flex-direction: row;
            }
        }
        .form-control{
            height: calc(1.5em + 0.6rem + 2px);
            padding: 0.3rem 0.75rem;
            font-size: .9rem;
        }
        .form-group {
            margin-bottom: .75rem;
        }

        .form-group .control-label {
            margin-bottom: 0.25rem;
            font-size: 1.05rem;
            font-weight:500;
        }

        fieldset legend {
            font-size: 1.5rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.6em;
            padding-bottom: 1rem;
            position: relative;
            color: var(--rjsf-main-color);
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 7px;
                width: 75px;
                background-color: var(--rjsf-main-color);
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 3px;
                height: 1px;
                width: 95%;
                max-width: 305px;
                background-color: var(--dark-blue-70);
            }
    
        }
        
        .me-btn,
        .me-btn:not(:disabled):not(.disabled):active{
            margin: .5rem 0 1rem 0;
            font-size: 1.4rem;
            letter-spacing: 1px;
            min-width: 200px;
            &.me-btn-submit{
                background-color: transparent !important;
                border-color: var(--app-button-color) !important;
                color: var(--app-button-color);
                &:hover {
                    transform: scale(1.05);
                    transition: all .2s;
                    background-color: var(--app-button-color) !important;
                    color: #fff;
                }
            }
            &.me-btn-outline{
                background-color: transparent !important;
                border-color: var(--app-button-bg) !important;
                color: var(--app-button-bg);
            }

            > i{
                margin-right: .5rem;
            }
        }
        .custom-color{
            .sketch-picker__close-container{
                position: relative;
                background-color: rgba(255,255,255,.97);
                color: #000;
                margin: 0 0 0 0;
                z-index: 10;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                padding: 0.125rem 0.25rem;
                // border: 1px solid #b9b9b9;
                border-bottom: 0;
                display: flex;
                justify-content: flex-end;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
                font-size: 1.5rem;
                &::after {
                    background-color: #fff;
                    // height: 7px;
                    content: "";
                    position: absolute;
                    // bottom: -7px;
                    width: 100%;
                    left: 0;
                    right: 0;
                }
                > i.far{
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
                
            }
        }

        input[type=checkbox]{
            transform: scale(1.5);
            padding: 10px;
        }
        .form-control:disabled,
        .form-control[readonly]{
            opacity: .57;
        }
        .array-item-toolbox{
            .btn-group{
                .btn{
                    padding: 0.275rem 0.75rem;
                    font-size: .9rem;
                }
                .array-item-remove{
                    border-radius: .25rem;
                }
            }
        }

        .panel-danger{
            &.errors{
                
                color: var(--mbox-basic-red);
                .panel-title{
                    color: var(--mbox-basic-red);
                }
                .list-group-item{
                    padding: .35rem .75rem;
                }
               
            }
        }
        .text-danger {
            color: var(--mbox-text-danger) ;
            color: var(--mbox-basic-red) !important;
        }
    }

}
