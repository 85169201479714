.custom-color{
    display: flex;
    justify-content: space-between;
    position:relative;
}
.custom-color > .custom-color__input{
    flex: 1;
    margin-right: 1rem;
    max-width: 75%;
    border: 5px solid #ccc;
    height: 2.5rem;
    margin :.125rem 0 .125rem 0;
    cursor: pointer;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    padding-left: .85rem;
}
.custom-color__input i.fas{
    background-color: rgba(255,255,255, .7);
    color: #000;
    padding: .125rem .25rem;
    border-radius: .125rem;

}
.custom-color .form-desc{
    flex: 0 1 25%;
    align-items: center;
    margin-left: 1rem;
}
.custom-color .form-desc > div{
    padding: 0 0 0 .25rem;
}
