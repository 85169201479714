.searchbar-container {
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding:.5rem;
    margin: 2rem 0;
}



.searchbar-input-container{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: relative;
}

@media only screen and (max-width: 576px) {
    .searchbar-input-container {
        width: 100%;
    }
  }

.searchbar-input-container_icon-container{
    font-size: 20px;
    margin-right: .5rem;
    position: absolute;
    left: 1rem;
    &.right{
        left: unset;
        right: 1rem;
        cursor: pointer;
        transition: all .15s;
        &:hover{
            transform: scale(1.1);
        }
    }
}

.searchbar-inputfield-container{
    
}
.searchbar-inputfield{
    background-color: transparent;
    width: 100%;
    /* box-shadow: var(--mbox-main-text-color) 0px 5px 15px; */
    border-radius: 15px;
    border: 2px solid var(--mbox-main-text-color);
    outline:  none;
    padding:.5rem 3rem;
    color:var(--mbox-main-text-color);
}



.searchbar-inputfield:focus{
    border: 3px solid var(--mbox-link-color);
    outline:none
}