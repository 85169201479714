@mixin animate-scale {
    transform: scale(1.1);
    transition: all .15s;
}

.cardWithIcon-container {
    background-color: var(--mbox-card-bg);
    position: relative;
    color:var(--mbox-main-text-color);
    margin: 1rem 0;
    min-height: 150px;
    border-radius: 8px;
    height: 85%;
    padding-left: 0;
    &__footer{
        display: flex;
        align-items:center;
        justify-content:flex-end;
        > * {
            margin-left: .5rem;
        }
        .btn-gear{
            font-size: 1.7rem;
            color: var(--mbox-basic-gray);
            cursor: pointer;
            &:hover{
             @include  animate-scale;
             transform: rotate(90deg) scale(1.23);
            }
        }
    }

    &__desc{
        font-size:.85rem;
        color: #6c757d;
    }


}

.card-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cardWithIcon-topMiddleIcon-container {
    position: absolute;
    background-color: var(--mbox-icon-background);
    border-radius: 50%;
    border: none;
    padding:.5rem;
    top:0;
    left:50%;
    transform: translate(-50%, -50%);
    color:var(--mbox-card-middle-icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height:50px;
}

.cardWithIcon-container:hover{
    box-shadow: rgba(246, 248, 250, 0.2) 0px 8px 24px;
}

.cardWithIcon-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top:.5rem;

    &__subtitle{
        font-size: 18px;
    } 
}

.cardWithIcon-link{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1rem;
    &:hover{
        @include  animate-scale;
    }
}

.cardWithIcon-link-iconcontainer {
    padding:.5rem;
    color: var(--mbox-link-color);
    border-radius: 50%;
    border: 2px solid var(--mbox-link-color);
    display: flex;
    align-items: center;
    justify-content: center;
    i{
        font-size: 16px;
    }
    
}

.cardWithIcon-link-iconcontainer:hover {
    background-color: var(--mbox-link-color);
    color:#fcfcfc;
}

.cardWithIcon-link-iconcontainer__secondary{
    color: var(--mbox-main-text-color);
    font-size: 22px;
}

.session-page{
    .cardWithIcon-container__footer {
        gap: .5rem;
        & > *{margin-left: 0;}
        .fa-qrcode{
            transition: transform 0.15s ease;
            cursor: pointer;
            &:hover{
                transform: scale(1.2);
            }
        }
    }
}