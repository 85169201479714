.image-upload--button{
    border:1px solid var(--mbox-link-color);
    color:var(--mbox-link-color);
    background: transparent;
    outline: transparent;
    padding: 5px 8px;
    border-radius: .25rem;
}

.image-upload--button:hover{
    background-color: var(--mbox-link-color);
    border: 1px solid transparent;
    color: var(--mbox-main-text-color);
    outline: transparent;
}
