.homePage-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.home-page{
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.homePage-title-container{
    display: flex;
    flex-direction: row;
    color:#fcfcfc;
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
    > img{
      object-fit: contain;
    }
}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

.homePage-input-container{
    margin-top: 2.5rem;
    /* width:80%; */
    min-width: 50%;
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.homePage-input-formItem{
    margin: 1.5rem 0;
}