.breadcrumb-item_link{
    color: var(--mbox-breadcrubs-color) !important;
    
}

.breadcrumb-item+.breadcrumb-item::before{
    content: ">";
    color: var(--mbox-main-text-color);
 }

 .breadcrumb-tooltip-container{
    display: flex;
    flex-direction: column;
    z-index: 999999;
 }

 .breadcrumb-tooltip-container-item{
    display: flex;
    flex-direction: row;

 }